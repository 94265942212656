<template>
    <div class="ma-1">
        
        <v-toolbar short dense color="primary" dark  flat>
            <v-toolbar-title>Estudios de laboratorio</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="cargaLabs">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-progress-linear
                v-if="loadingLab"
                :active="loadingLab"
                :indeterminate="loadingLab"
                absolute
                bottom
                color="white"
            ></v-progress-linear>
        </v-toolbar>
        <v-sheet class="mb-2" elevation="2">
            <v-list dense>
                <v-list-item
                    v-for="(item, i) in listLab"
                    :key="i"
                >
                <v-list-item-icon >
                    <v-icon color="red" v-if="item.urgencia">mdi-alert-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>
                        {{item.internamiento}} {{item.dateCreated}} por {{item.medico}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        <ul>
                            <li
                                v-for="(itm, j) in item.items" :key="j"
                            >
                                <b>{{itm.itemNombre}}</b><br/>{{itm.observaciones}}
                            </li>
                            
                        </ul>
                    </v-list-item-subtitle>
                        {{item.observaciones}}
                    <v-divider/>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-sheet>


        <v-toolbar short dense color="primary" dark  flat>
            <v-toolbar-title>Estudios de imagenología</v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="cargaImagen">
                <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-progress-linear
                v-if="loadingRx"
                :active="loadingRx"
                :indeterminate="loadingRx"
                absolute
                bottom
                color="white"
            ></v-progress-linear>
        </v-toolbar>
        <v-sheet class="mb-2" elevation="2">
            <v-list dense>
                <v-list-item
                    v-for="(item, i) in listRx"
                    :key="i"
                >

                <v-list-item-content>
                    <v-list-item-title>
                        {{item.dateCreated}} por {{item.medico}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{item.estudioNombre}} - {{item.descripcion}}
                    </v-list-item-subtitle>
                        {{item.observaciones}}
                    <v-divider/>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-sheet>

    </div>
    
</template>

<script>
export default {

    mounted(){
        this.cargaLabs()
        this.cargaImagen()
    },
    
    props:{
        estaFecha:String,
        internamiento:Number,
    },
    data:()=>({
        listLab:[],
        loadingLab:false,
        listRx:[],
        loadingRx:false,
        listIc:[],
    }),

    methods:{
        async cargaLabs(){
            this.loadingLab=true
            let params = {
                fechaDeBusqueda: this.estaFecha2,
                internamientos:this.internamiento,
                internamiento:this.internamiento,
            }
            try {
                //listEstudios
                let la = await this.$http({
                    method:'GET',
                    url:'/laboratorio/listEstudios',
                    params:params,
                })
                this.loadingLab=false
                this.listLab = la.data.root
            }catch(err){
                this.loadingLab=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaImagen(){
            this.loadingRx = true
            let params = {
                fechaDeBusqueda: this.estaFecha2,
                internamientos:this.internamiento,
                internamiento:this.internamiento,
            }
            try {
                //listEstudios
                let la = await this.$http({
                    method:'GET',
                    url:'/imagen/listEstudios',
                    params:params,
                })
                this.loadingRx = false
                this.listRx = la.data.root
            }catch(err){
                this.loadingRx = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        estaFecha2(){
            return this.estaFecha.replace(/-/g,'/')
        },
    },
    watch:{
        estaFecha(){
            this.cargaLabs()
            this.cargaImagen()
        }
    },

}
</script>

<style>

</style>